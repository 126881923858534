import React, { useState, useEffect} from 'react';
import './FAQSection.css'; 

const FAQSection = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  useEffect(() => {
    const hash = window.location.hash;
    if (hash === '#faqSection') {
      const faqElement = document.getElementById('faqSection');
      if (faqElement) {
        faqElement.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, []);

  const toggleAccordion = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  const faqItems = [
    {
      question: "What is Xenonify?",
      answer: "Bring AI to every corner of your business. Supercharge IT and developers. Empower customer service. Reinvent how your people work. Bring AI to every corner of your business. Supercharge IT and developers. Empower customer service. Reinvent how your people work."
    },
    {
      question: "How does FinOps go hand in hand with organization's budget planning?",
      answer: "FinOps integrates financial operations with budgeting to ensure cost optimization, strategic planning, and efficient resource allocation."
    },
    {
      question: "How will this be integrated with my existing workflow?",
      answer: "Our tools are designed to seamlessly integrate into your existing workflow, offering easy configuration, automation, and smooth transitions."
    },
    {
      question: "How will this secure my data?",
      answer: "We ensure enterprise-grade security protocols, encryption, and compliance with industry standards to protect your data."
    },
    {
      question: "How can I book a demo for this and how can I get started?",
      answer: "Simply click on the 'Book Demo' button on our pricing section to schedule a free demo and see how FinOps can revolutionize your business."
    }
  ];

  return (
    <div className="faq-section" id='faqSection'>
        <button>FAQ</button>
      <h2>Frequently Asked Questions</h2>
      <div className="faq-items">
        {faqItems.map((item, index) => (
          <div key={index} className={`faq-item ${activeIndex === index ? 'active' : ''}`}>
            <div className="faq-question" onClick={() => toggleAccordion(index)}>
              <h3>{item.question}</h3>
              <span className="faq-icon">{activeIndex === index ? '-' : '+'}</span>
            </div>
            {activeIndex === index && <p className="faq-answer">{item.answer}</p>}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQSection;
