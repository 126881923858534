// EnterpriseSection.js
import React from 'react';
import './EnterpriseSection.css';
import supportIcon from '../../assets/EnterpriseSectionCards/enterprisecard1.svg';
import costIcon from '../../assets/EnterpriseSectionCards/enterprisecard2.svg';
import securityIcon from '../../assets/EnterpriseSectionCards/enterprisecard3.svg';
import testingIcon from '../../assets/EnterpriseSectionCards/enterprisecard4.svg';
import governanceIcon from '../../assets/EnterpriseSectionCards/enterprisecard5.svg'; 
import consultationIcon from '../../assets/EnterpriseSectionCards/enterprisecard6.svg';

const EnterpriseSection = () => {
    return (
      <section className="enterprise-section">
        <div className="enterprise-header">
          <button>Enterprise</button>
          <h1>How it all comes together</h1>
          <p>Why the largest and fastest growing companies choose FinOps</p>
        </div>
  
        <div className="enterprise-cards">
          <Card 
            title="Dedicated Support & Consultation"
            description="Access a team of FinOps experts dedicated to helping you implement and refine financial operations practices."
            icon={consultationIcon}
          />
          <Card 
            title="Automated Cost Allocation"
            description="Simplify complex billing by automating cost allocation across departments and projects."
            icon={costIcon}
          />
          <Card 
            title="Enterprise-Level Security"
            description="Ensure your financial data is protected with enterprise-grade security protocols."
            icon={securityIcon}
          />
          <Card 
            title="Comprehensive Testing Framework"
            description="Simplify compliance testing by automating testing across departments and projects."
            icon={testingIcon}
          />
          <Card 
            title="Compliance and Governance"
            description="Ensure your financial data is protected with enterprise-grade security protocols."
            icon={governanceIcon}
          />
          <Card 
            title="Expert Consultation"
            description="Get advice from FinOps specialists to enhance and optimize your financial operations."
            icon={supportIcon}
          />
        </div>
      </section>
    );
  };
  
  const Card = ({ title, description, icon }) => {
    return (
      <div className="enterprise-card">
        <img src={icon} alt={title} className="enterprise-card-icon" />
        <h3>{title}</h3>
        <p>{description}</p>
      </div>
    );
  };
  
export default EnterpriseSection;
