import React from 'react';
import './solution.css';
import SolutionIcon from '../../assets/SolutionSection/SolutionIcon.svg';
import FinancialStewardshipImg from '../../assets/SolutionSection/financial-stewardship-sol.svg';
import StategicOversightImg from '../../assets/SolutionSection/strategic-oversight-sol.svg';

const SolutionSection = () => {
    return (
        <section className="solution-section">
            <div className="solution-header">
                <button className="solution-subheading">Solution</button>
            </div>
            <h1 className="solution-main-heading">Redefining Cloud Cost Management <br/>With Engineering Discipline</h1>
            <div className="solution-cards">
                <div className="solution-card">
                    <img src={SolutionIcon} alt="Solution Card 1" className="solution-card-image" />
                    <div className="solution-card-content">
                        <div className="solution-card-header">
                            <h3 className="solution-card-heading">Financial Stewardship</h3>
                        </div>
                        <p className="solution-card-description">Managing budgeting and expense tracking, ensuring financial discipline and alignment with organizational goals.</p>
                        <img src={FinancialStewardshipImg} alt="Card Image" className="solution-card-bottom-image" />
                    </div>
                </div>
                <div className="solution-card">
                    <img src={SolutionIcon} alt="Solution Card 2" className="solution-card-image" />
                    <div className="solution-card-content">
                        <div className="solution-card-header">
                            <h3 className="solution-card-heading">Strategic Oversight</h3>
                        </div>
                        <p className="solution-card-description">Guiding strategic financial decisions and initiatives, aligning them with business goals and fostering accountability</p>
                        <img src={StategicOversightImg} alt="Card Image" className="solution-card-bottom-image" />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default SolutionSection;
