import React, { useState } from 'react';
import './demoSection.css';
import DemoImage from '../../assets/DemoSection/YTVideoImg.svg';

const DemoSection = () => {
    const [showVideo, setShowVideo] = useState(false);

    const openVideo = () => {
        setShowVideo(true);
    };

    return (
        <section className="demo-section">
            <div className="demo-header">
                <button className="demo-small-heading">See In Action</button>
                <h1 className="demo-main-heading">Watch Our Product Transform <br /> Your Workflow</h1>
            </div>
            <div className="demo-image-container">
                {!showVideo ? (
                    <img
                        src={DemoImage}
                        alt="Product Demo"
                        className="demo-image"
                        onClick={openVideo}
                    />
                ) : (
                    <iframe
                        src="https://www.youtube.com/embed/ANBacXs7WQs?si=rT1OZt3llX7dPTP4"
                        allow="autoplay; encrypted-media"
                        allowFullScreen
                        className="embedded-video"
                    />
                )}
            </div>
        </section>
    );
};

export default DemoSection;
