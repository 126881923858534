import React, { useState, useRef, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Navbar.css'; 
import logo from '../../assets/Navbar/xenonify-logo.svg';

const Navbar = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);
  const hamburgerRef = useRef(null);
  const navigate = useNavigate();

  const toggleMenu = () => {
    setMenuOpen(prevState => !prevState);
  };

  const handleClickOutside = (event) => {
    if (
      menuRef.current && !menuRef.current.contains(event.target) &&
      hamburgerRef.current && !hamburgerRef.current.contains(event.target)
    ) {
      setMenuOpen(false);
    }
  };

  const scrollToFAQ = () => {
    navigate('/pricing#faqSection');
    
    setTimeout(() => {
      const faqElement = document.getElementById('faqSection');
      if (faqElement) {
        const yOffset = -100; 
        const yPosition = faqElement.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: yPosition, behavior: 'smooth' });
      }
    }, 100); 
  };

  useEffect(() => {
    if (isMenuOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isMenuOpen]);

  return (
    <nav className="navbar">
      <div className="logo">
        <Link to="/">
          <img src={logo} alt="Logo" />
        </Link>
      </div>
      <div className="sections">
        <a href="/enterprise">Enterprise</a>
        <a href="/pricing">Pricing</a>
        <button onClick={scrollToFAQ}>FAQ</button>
      </div>
      <div className="auth-buttons">
        <button className="login-btn">Login</button>
        <button className="signup-btn">Sign Up</button>
      </div>
      <div
        className="hamburger"
        onClick={toggleMenu}
        ref={hamburgerRef}
      >
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div className={`navbar-menu ${isMenuOpen ? 'open' : ''}`} ref={menuRef}>
        <div className="sections">
          <a href="/enterprise">Enterprise</a>
          <a href="/pricing">Pricing</a>
          <button onClick={scrollToFAQ}>FAQ</button>
        </div>
        <div className="auth-buttons">
          <button className="login-btn">Login</button>
          <button className="signup-btn">Sign Up</button>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
