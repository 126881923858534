import React from 'react';
import bannerLogo from '../../assets/Banner/BannerLogo.svg';
import TrustedBy from '../trustedbysection/trustedby';
import './EnterpriseBanner.css'

const EnterpriseBanner = () => {

  const handleBookDemoClick = () => {
    window.location.href = 'https://demo.xenonify.ai';
  };

  return (
    <section className="enterprise-banner">
      <div className="banner-content">
        <img src={bannerLogo} alt="Icon" className="banner-icon" />
        <h1 className="banner-heading">Enterprise-Ready Financial<br /> Operations</h1>
        <p className="banner-paragraph">
        Why the largest and fastest growing companies choose FinOps
        </p>
        <button className="book-demo-button" onClick={handleBookDemoClick}>Book Demo</button>
      </div>
      <TrustedBy />
    </section>
  );
};

export default EnterpriseBanner;
