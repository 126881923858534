import React, { useState } from 'react';
import './automation.css'; 
import AutomationActive from '../../assets/AutomationSection/automationActive.svg';
import AutomationInactive from '../../assets/AutomationSection/automationFinops.svg';

const AutomationSection = () => {
    const [hoveredCard, setHoveredCard] = useState(null);
    const handleMouseEnter = (index) => {
        setHoveredCard(index);
    };
    const handleMouseLeave = () => {
        setHoveredCard(null);
    };

    const cards = [
        { title: "FinOps for Azure", description: "Controls and reduces spending in Azure through tailored cost management and optimization strategies.", icon: AutomationInactive, activeIcon: AutomationActive },
        { title: "FinOps for AWS", description: "Manages and optimizes costs for Amazon Web Services, focusing on efficient resource use and budget control.", icon: AutomationInactive, activeIcon: AutomationActive },
        { title: "FinOps for Databricks", description: "Optimizes costs and resource usage for Databricks, enhancing efficiency in data processing.", icon: AutomationInactive, activeIcon: AutomationActive },
        { title: "FinOps for Snowflake", description: "Manages and optimizes expenses in Snowflake’s data platform for better cost efficiency.", icon: AutomationInactive, activeIcon: AutomationActive },
        { title: "FinOps for Hybrid Cloud", description: "Balances and optimizes costs across both on-premises and cloud environments in a hybrid setup.", icon: AutomationInactive, activeIcon: AutomationActive },
    ];

    return (
        <section className="automation-section">
            <div className="automation-header">
                <button className="small-heading">Automation</button>
                <h1 className="large-heading">Bridging Your Tools For Optimal <br/>Performance</h1>
            </div>
            <div className="automation-cards">
                <div className="automation-card-row top-row">
                    {cards.slice(0, 3).map((card, index) => (
                        <div className="automation-card" 
                             onMouseEnter={() => handleMouseEnter(index)} 
                             onMouseLeave={handleMouseLeave} 
                             key={index}>
                            <div className="card-header">
                                <img src={hoveredCard === index ? card.activeIcon : card.icon} alt={`Tool ${index + 1}`} className="card-icon" />
                                <h3 className={`automation-card-heading ${hoveredCard === index ? 'hovered' : ''}`}>{card.title}</h3>
                            </div>
                            <p className={`card-description ${hoveredCard === index ? 'hovered' : ''}`}>{card.description}</p>
                        </div>
                    ))}
                </div>
                <div className="automation-card-row bottom-row">
                    {cards.slice(3).map((card, index) => (
                        <div className="automation-card" 
                             onMouseEnter={() => handleMouseEnter(index + 3)} 
                             onMouseLeave={handleMouseLeave} 
                             key={index + 3}>
                            <div className="card-header">
                                <img src={hoveredCard === index + 3 ? card.activeIcon : card.icon} alt={`Tool ${index + 4}`} className="card-icon" />
                                <h3 className={`automation-card-heading ${hoveredCard === index + 3 ? 'hovered' : ''}`}>{card.title}</h3>
                            </div>
                            <p className={`card-description ${hoveredCard === index + 3 ? 'hovered' : ''}`}>{card.description}</p>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default AutomationSection;
