import React from 'react';
import './Banner.css';
import dashboardImage from '../../assets/Banner/DashboardAnimatedVideo.mp4';
import bannerLogo from '../../assets/Banner/BannerLogo.svg';
import TrustedBy from '../trustedbysection/trustedby';

const Banner = () => {

  const handleBookDemoClick = () => {
    window.location.href = 'https://demo.xenonify.ai';
  };

  return (
    <section className="banner">
      <div className="banner-content">
        <img src={bannerLogo} alt="Icon" className="banner-icon" />
        <h1 className="banner-heading">Harnessing Gen AI <br/> For Financial Operations</h1>
        <p className="banner-paragraph">
          Leverage generative AI to transform your financial operations. Our advanced solutions streamline processes, improve accuracy, and boost efficiency by automating complex tasks.
        </p>
        <button className="book-demo-button" onClick={handleBookDemoClick}>Book Demo</button>
      </div>

      <div className="dashboard-image-container">
        <div className="video-outer-wrapper">
          <div className="video-inner-wrapper">
            <video
              className="dashboard-image"
              autoPlay
              loop
              muted
              playsInline
            >
              <source src={dashboardImage} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </div>
      
      <TrustedBy />
    </section>
  );
};

export default Banner;
