import React from 'react';
import bannerLogo from '../../assets/Banner/BannerLogo.svg';
import './PricingBanner.css'
import PricingCards from '../PricingCards/PricingCards';

const PricingBanner = () => {

  return (
    <section className="pricing-banner">
      <div className="banner-content">
        <img src={bannerLogo} alt="Icon" className="banner-icon" />
        <h1 className="banner-heading">Pricing for Smarter <br /> FinOps</h1>
        <p className="banner-paragraph">
        No hidden fees—just powerful tools to streamline your cloud financial management.
        </p>
      </div>
        <PricingCards />
    </section>
  );
};

export default PricingBanner;
