import React from 'react';
import './Explore.css'
import { useNavigate } from 'react-router-dom';

const ExploreSection = () => {
    const navigate = useNavigate(); 

    const handleExploreClick = () => {
      navigate('/enterprise');
    };
  return (
    <section className="explore-section">
      <div className="explore-content">
        <h2>Enterprise</h2>
        <p>
        With Enterprise get advanced features and dedicated <br/> support, tailored to large organizations.
        </p>
        <button className="explore-now-btn" onClick={handleExploreClick}>Explore Now</button>
      </div>
    </section>
  );
};

export default ExploreSection;
