import React from 'react';
import googleLogo from '../../assets/Banner/google-logo-2.svg';
import intelLogo from '../../assets/Banner/intel-logo-1.svg';
import chubLogo from '../../assets/Banner/chubb-logo-1.svg';
import ibmLogo from '../../assets/Banner/ibm-logo-1.svg';
import skLogo from '../../assets/Banner/SK.svg';
import mellonLogo from '../../assets/Banner/bny-mellon-logo.svg';
import linkedinLogo from '../../assets/Banner/linkedin-logo-1.svg';
import uberLogo from '../../assets/Banner/uber-logo-1.svg';
import './trustedby.css'

const TrustedBy = () => {
    return(
        <section className="trusted-by">
        <h3 className="trusted-by-heading">Trusted By 25+ Companies</h3>
        <div className="trusted-by-logos">
          <img src={googleLogo} alt="Google" className="logo" />
          <img src={intelLogo} alt="Intel" className="logo" />
          <img src={chubLogo} alt="Chub" className="logo" />
          <img src={ibmLogo} alt="IBM" className="logo" />
          <img src={skLogo} alt="SK" className="logo" />
          <img src={mellonLogo} alt="Mellon" className="logo" />
          <img src={linkedinLogo} alt="LinkedIn" className="logo" />
          <img src={uberLogo} alt="Uber" className="logo" />
        </div>
      </section>
    )
};

export default TrustedBy;