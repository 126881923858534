import React from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import Navbar from './components/navbar/Navbar';
import Banner from './components/Banner/Banner';
import ProblemSection from './components/problemsection/ProblemSection';
import AutomationSection from './components/automation/automation';
import SolutionSection from './components/SolutionSection/solution';
import DemoSection from './components/demovideo/demoSection';
import SavingsSection from './components/savings/savings';
import FeaturesSection from './components/features/features';
import VisibilitySection from './components/visibility/visibility';
import RecommendationsSection from './components/recommendations/recommendation';
import CTASection from './components/ctasection/ctasection';
import Footer from './components/footer/footer';
import EnterpriseBanner from './components/EnterpriseBanner/EnterpriseBanner';
import EnterpriseSection from './components/EnterpriseSection/EnterpriseSection';
import PricingBanner from './components/PricingBanner/Pricingbanner';
import FAQSection from './components/FAQSection/FAQSection';
import ExploreSection from './components/EnterpriseExploreSection/Explore';


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/enterprise" element={<Enterprise />} />
        <Route path="/pricing" element={<Pricing />} />
      </Routes>
    </Router>
  );
}

const Main = () => {
  return (
    <>
      <Navbar />
      <Banner />
      <ProblemSection />
      <AutomationSection />
      <SolutionSection />
      <DemoSection />
      <SavingsSection />
      <FeaturesSection />
      <VisibilitySection />
      <RecommendationsSection />
      <CTASection />
      <Footer />
    </>
  );
};
const Enterprise = () => {
  return (
    <>
      <Navbar />
      <EnterpriseBanner />
      <EnterpriseSection />
      <CTASection />
      <Footer />
    </>
  );
};
const Pricing = () => {
  return (
    <>
      <Navbar />
      <PricingBanner />
      <ExploreSection />
      <FAQSection/>
      <Footer />
    </>
  );
};

export default App;
