import React from 'react';
import './PricingCards.css';
import infoIcon from '../../assets/PricingSection/Tick-Square.svg'; 

const PricingCards = () => {
  
  const handleBookDemoClick = () => {
    window.location.href = 'https://demo.xenonify.ai';
  };
  
  return (
    <div className="pricing-section">
      <div className="pricing-card">
        <h2>Start</h2>
        <p>Get started with essential FinOps tools</p>
        <h1>$0</h1>
        <ul>
          <li><img src={infoIcon} alt="Info" className="info-icon" /> Basic cost tracking and monitoring</li>
          <li><img src={infoIcon} alt="Info" className="info-icon" /> Unlimited data retention</li>
          <li><img src={infoIcon} alt="Info" className="info-icon" /> Access to standard reporting tools</li>
          <li><img src={infoIcon} alt="Info" className="info-icon" /> Customer Support</li>
          <li><img src={infoIcon} alt="Info" className="info-icon" /> Up to 3 user accounts</li>
        </ul>
        <button onClick={handleBookDemoClick}>Book Demo</button>
      </div>

      <div className="pricing-card">
        <h2>Level Up</h2>
        <p>Unlock the full potential for FinOps</p>
        <h1>$499 <span>/month</span></h1>
        <ul>
          <li><img src={infoIcon} alt="Info" className="info-icon" /> Advanced cost optimization</li>
          <li><img src={infoIcon} alt="Info" className="info-icon" /> Unlimited data retention</li>
          <li><img src={infoIcon} alt="Info" className="info-icon" /> Account management & Support</li>
          <li><img src={infoIcon} alt="Info" className="info-icon" /> Scalable to match large teams</li>
          <li><img src={infoIcon} alt="Info" className="info-icon" /> Custom integrations & API access</li>
        </ul>
        <button onClick={handleBookDemoClick}>Book Demo</button>
      </div>
    </div>
  );
};

export default PricingCards;
